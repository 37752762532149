// import axios, { AxiosResponse } from 'axios';
// // config
import axios from 'axios';
// config
import { SERVER_API } from '../config-global';
// ----------------------------------------------------------------------
const apiKey = axios.create({ baseURL: SERVER_API });
apiKey.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('accessToken');
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
}, (error) => {
    Promise.reject(error?.data?.message);
});
apiKey.interceptors.response.use((response) => response, (error) => {
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
});
export default apiKey;
